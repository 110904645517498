import './App.css';
import Portfolio from './Portfolio';

function App() {
  return (
    < Portfolio />
  );
}

export default App;
