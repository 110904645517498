import React from 'react';
import { Github, Linkedin, ExternalLink } from 'lucide-react';

const Portfolio = () => {
    const projects = [
        {
            id: 0,
            title: "Devanagari Number Recognition",
            description: "A comparative study of ML models (KNN, K-means, SVM, ANN) for handwritten Devanagari digit classification, achieving 97% accuracy. Features an interactive web app with custom preprocessing for real-time digit recognition.",
            technologies: ["Python", "FastAPI", "ReactJS", "Docker", "Digital Ocean", "TensorFlow"],
            githubUrl: "https://github.com/imnabid/devanagari-number-recognition",
            imageUrl: "https://github.com/imnabid/devanagari-number-recognition/raw/main/demo/demo.png",
            width: "full",
            height: "full",
            tag: "Live Demo!",
            deployLink: "http://64.227.163.50/",
        }
        ,
        
        {
            id: 2,
            title: "Foodie: Food Delivery Website",
            description: "Full-stack web application for ordering food online, with attractive admin dashboard.",
            technologies: ["React", "Django", "PostgresQL", "Django REST Framework", "Oauth2"],
            githubUrl: "https://github.com/imnabid/foodie",
            imageUrl: "https://github.com/imnabid/foodie/raw/main/demo/Screenshot%202024-01-08%20143311.png",
            width: "full",
            height: "full",
        },
        {
            id: 7,
            title: "Foodie: Mobile App",
            description: "Mobile app for ordering food online, built using React Native and Node.js.",
            technologies: ["React Native", "Node.js", "MongoDB", "Express.js"],
            githubUrl: "https://github.com/imnabid/foodie-react-native",
            imageUrl: "https://github.com/imnabid/foodie-react-native/raw/main/demo/c1.jpg",
            width: "300",
            height: "full",
        },
        {
            id: 1,
            title: "Baghchal Game AI",
            description: "An AI agent that plays the traditional Nepali board game Baghchal using Minimax algorithm.",
            technologies: ["C++", "SFML", "Minimax"],
            githubUrl: "https://github.com/imnabid/baghchal-ai",
            imageUrl: "https://raw.githubusercontent.com/imnabid/baghchal-ai/refs/heads/main/assets/1.png",
            width: "full",
            height: "full",
        },
        {
            id: 3,
            title: "P2P File Sharing System",
            description: "A peer-to-peer file sharing system using TCP/IP sockets in Rust. Supports file upload/download with local network discovery. Inpired by bit-torrent protocol.",
            technologies: ["Rust", "TCP/IP", "Multithreading", "Tauri GUI"],
            githubUrl: "https://github.com/imnabid/p2p-filesharing",
            imageUrl: "https://github.com/imnabid/p2p-filesharing/raw/main/images/2.png",
            width: "full",
            height: "full",
        },
        {
            id: 4,
            title: "Image Compression Using Bezier Curves",

            description: `This project demonstrates an image compression method for 600x600 RGB images by applying cubic Bézier curves. The technique significantly reduces storage space while
      maintaining visual quality, achieving a compression ratio of approximately 6:1.`,
            technologies: ["Python", "OpenCV", "Bezier Curves"],
            githubUrl: "https://github.com/imnabid/img-compression",
            imageUrl: "https://github.com/imnabid/img-compression/raw/main/demo/8.png",
            width: "full",
            height: "full",
        },
        {
            id: 5,
            title: "FaceTrace: Face Recognition System",
            description: `FaceTrace is an advanced facial recognition system designed to aid in locating missing persons in Nepal. The project leverages Convolutional Neural Networks (CNNs), specifically utilizing the Siamese Neural Network
      and VGG16 architecture, for accurate facial feature extraction and recognition.`,
            technologies: ["TensorFlow", "Keras", "OpenCV", "Siamese Neural Network", "VGG16", "MongoDB"],
            githubUrl: "https://github.com/imnabid/facetrace",
            imageUrl: "https://github.com/imnabid/facetrace/raw/main/demo/8.png",
            width: "full",
            height: "full",
        },
        {
            id: 6,
            title: "Kathmandu Univeristy Shortest Path Finder",
            description: `A web application that finds the shortest path between two
            locations within the Kathmandu University campus. The project uses Dijkstra's algorithm to calculate the shortest path.`,
            technologies: ["React", "Python", "NetworkX", "Dijkstra's Algorithm", "Leaflet Maps"],
            githubUrl: "https://github.com/imnabid/ku-shortest-path",
            imageUrl: "https://github.com/imnabid/ku-shortest-path/raw/main/demo/shortest_path.png",
            width: "full",
            height: "full",
        }
    ];

    const primaryColor = "rgb(235, 74, 41)";

    const handleDownloadCV = () => {
        const cvUrl = require("./files/Nabin_Pd_Dhakal_CV.pdf");
        const link = document.createElement('a');
        link.href = cvUrl;
        link.download = 'Nabin_Pd_Dhakal_CV.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="min-h-screen bg-gray-50 text-gray-800" style={{ fontFamily: "'Roboto Mono', monospace" }}>
                <style>
                    {`
          @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');
        `}
                </style>

                {/* Header */}
                <header className="bg-white shadow-sm">
                    <div className="max-w-5xl mx-auto px-4 py-6 sm:px-6 lg:px-8 flex justify-between items-center">
                        <h1 className="text-2xl font-bold" style={{ color: primaryColor }}>Nabin Pd. Dhakal</h1>
                        <nav>
                            <ul className="flex space-x-8">
                                {/* <li><a href="#about" className="hover:text-red-500" style={{ color: "inherit", ":hover": { color: primaryColor } }}>About</a></li> */}
                                <li><a href="#projects" className="hover:text-red-500" style={{ color: "inherit", ":hover": { color: primaryColor } }}>Projects</a></li>
                                <li><a href="#contact" className="hover:text-red-500" style={{ color: "inherit", ":hover": { color: primaryColor } }}>Contact</a></li>
                            </ul>
                        </nav>
                    </div>
                </header>
                {/* About Section */}
                <section id="about" className="py-16 bg-white">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: primaryColor }}>About Me</h2>
                        <div className="flex flex-col md:flex-row gap-8 items-center">
                            <div className="w-full md:w-1/3 flex justify-center">
                                <div className="w-48 h-48 rounded-full overflow-hidden">
                                    <img src={require("./images/profile.jpg")} alt="Nabin Prasad Dhakal" className="w-full h-full object-cover" />
                                </div>
                            </div>
                            <div className="w-full md:w-2/3">
                                <p className="text-lg mb-4">
                                    I'm a senior Computer Science student with a passion for building web applications and exploring new technologies.
                                    My coursework has given me a strong foundation in algorithms, data structures, and software engineering principles.
                                </p>
                                <p className="text-lg">
                                    I enjoy tackling complex problems and creating clean, efficient solutions. Outside of tech, I enjoy exploring world economics,
                                    understanding how global markets, trade policies, and financial systems shape industries and innovation.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Hero Section */}
                <section className="bg-white">
                    <div className="max-w-5xl mx-auto px-4 py-16 sm:px-6 lg:px-8 text-center">
                        <h2 className="text-4xl font-extrabold mb-4">Computer Science Student</h2>
                        <p className="text-xl max-w-2xl mx-auto mb-8">
                            Passionate about software development and creating efficient solutions to complex problems.
                        </p>
                        <button
                            onClick={handleDownloadCV}
                            className="text-white font-medium py-2 px-6 rounded-md transition-colors"
                            style={{ backgroundColor: primaryColor, ":hover": { backgroundColor: "rgb(211, 67, 37)" } }}
                        >
                            Download CV
                        </button>
                    </div>
                </section>

                {/* Projects Section */}
                <section id="projects" className="py-16">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-bold mb-12 text-center" style={{ color: primaryColor }}>My Projects</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {projects.map((project) => (
                                <div key={project.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow relative">
                                    {/* NEW Tag */}
                                    {project.tag && (
                                        <div 
                                            className="absolute top-4 right-4 px-3 py-1 rounded-md text-sm font-bold text-white"
                                            style={{ 
                                                backgroundColor: primaryColor,
                                                fontSize: '1rem',
                                                fontWeight: '700',
                                                zIndex: 10,
                                                letterSpacing: '0.05em'
                                            }}
                                        >
                                            {project.tag}
                                        </div>
                                    )}
                                    {/* Project Image */}
                                    <div className="h-48 overflow-hidden flex justify-center items-center">
                                        <img
                                            src={project.imageUrl}
                                            alt={project.title}
                                            className={`w-${project.width} h-${project.height} object-cover`}
                                        />
                                    </div>
                                    <div className="p-6">
                                        <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                                        <p className="text-gray-600 mb-4">{project.description}</p>
                                        <div className="mb-4 flex flex-wrap gap-2">
                                            {project.technologies.map((tech, index) => (
                                                <span
                                                    key={index}
                                                    className="px-2 py-1 rounded-md text-sm text-white"
                                                    style={{ backgroundColor: primaryColor }}
                                                >
                                                    {tech}
                                                </span>
                                            ))}
                                        </div>
                                        <div className="flex space-x-4">
                                            <a
                                                href={project.githubUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline-flex items-center"
                                                style={{ color: primaryColor }}
                                            >
                                                <Github size={16} className="mr-2" />
                                                View on GitHub
                                            </a>
                                            {project.deployLink && (
                                                <a
                                                    href={project.deployLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="inline-flex items-center"
                                                    style={{ color: primaryColor }}
                                                >
                                                    <ExternalLink size={16} className="mr-2" />
                                                    Live Demo
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>



                {/* Contact Section */}
                <section id="contact" className="py-16">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                        <h2 className="text-3xl font-bold mb-8" style={{ color: primaryColor }}>Get In Touch</h2>
                        <p className="text-lg mb-8 max-w-2xl mx-auto">
                            I'm currently looking for internship or entry-level opportunities. Feel free to reach out if you'd like to connect!
                        </p>
                        <div className="flex flex-wrap gap-6 justify-center space-x-6">
                            <a href="mailto:nabindhakal33@gmail.com" style={{ color: primaryColor }}>nabindhakal33@gmail.com</a>

                            <a
                                href="https://www.linkedin.com/in/imnabin"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex justify-center items-center"

                                style={{ color: primaryColor, margin:0 }}
                            >
                                <Linkedin size={14} />
                                <p className="ml-0.5">LinkedIn</p>
                            </a>
                            <a
                                href="https://github.com/imnabid"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center"
                                style={{ color: primaryColor, margin:0 }}
                            >
                                <Github size={14} />
                                <p className="ml-0.5">Github</p>
                            </a>
                        </div>
                    </div>
                </section>

                {/* Footer */}
                <footer style={{ backgroundColor: primaryColor }} className="text-white py-8">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                        <p>© {new Date().getFullYear()} Nabin Prasad Dhakal. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default Portfolio;
